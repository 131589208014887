@charset "UTF-8";
@use "../utility/variable" as var;

  /* MV
------------------------------- */

.mv {
  height: 640px;
  position: relative;

  &__img {
    object-position: 50% 50%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__in {
    color: #fff;
    padding-top: 222px;
    position: relative;
    z-index: 1;

    &__ttl {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 17px;
    }

    &__text {
      font-size: 25px;
      font-weight: 400;
      line-height: 46px;
      letter-spacing: 0.2em;
      text-align: center;
      margin-bottom: 38px;
    }

    .btn-search {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .btn-anchor {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  @media only screen and (max-width: 767px) {
    height: 58.6rem;

    &__in {
      padding-top: 18.6rem;
      
      &__ttl {
        font-size: 1.3rem;
        line-height: 1.4;
        margin-bottom: 1rem;
      }

      &__text {
        font-size: 2.2rem;
        line-height: 3.6rem;
        margin-bottom: 3rem;
      }
    }
  }
}

/*introduction
------------------------------- */

.intro {
  padding-top: 91px;
  padding-bottom: 162px;
  position: relative;
  overflow: hidden;

  &__in {
    @include var.fxb_s_s;
    width: 1000px;
    max-width: 94%;
    position: relative;
    z-index: 1;

    &__cell {
      &._blockA {
        width: calc(100% - 474px);
        padding-top: 93px;
      }

      &._blockB {
        width: 474px;

        img {
          display: block;
          width: 100%;
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 960px)  {
        &._blockA {
          width: 450px;
        }
        &._blockB {
          width: calc(100% - 450px);
        }
      }
    }
  }

  &__ttl-large {
    font-family: "Quattrocento", serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.05em;
    color: var.$colorDbe;
    margin-bottom: 39px;

    span {
      display: block;

      &._subA {
        font-size: 13px;
        margin-bottom: 24px;
      }

      &._subB {
        font-size: 14px;
        margin-top: 3px;
      }
    }
  }

  &__ttl-mid {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.95;
    letter-spacing: 0.05em;
    margin-bottom: 22px;
  }

  &__p {
    font-size: 13px;
    font-weight: 400;
    line-height: 2.23;
    letter-spacing: 0.05em;
    margin-bottom: 36px;
  }

  &__img02 {
    display: block;
    width: 672px;
    position: absolute;
    bottom: -264px;
    right: 50%;
    margin-right: 101px;
  }
  
  @media only screen and (max-width: 767px) {
    padding-top: 2.5rem;
    padding-bottom: 10rem;

    &__in {
      @include var.fxb;
      flex-direction: column-reverse;
      width: 29.5rem;

      &__cell {
        &._blockA {
          width: 100%;
          padding-top: 5rem;
        }

        &._blockB {
          width: 100%;
        }
      }
    }

    &__ttl-large {
      font-size: 2.6rem;
      margin-bottom: 3.1rem;

      span {

        &._subA {
          font-size: 1.3rem;
          margin-bottom: 1.7rem;
        }

        &._subB {
          font-size: 1.3rem;
          margin-top: 0.3rem;
        }
      }
    }

    &__ttl-mid {
      font-size: 1.8rem;
      line-height: 3.9rem;
      letter-spacing: 0;
      margin-bottom: 1.7rem;
    }

    &__p {
      font-size: 1.3rem;
      line-height: 2.9rem;
      letter-spacing: 0;
      margin-bottom: 4rem;
    }

    &__img02 {
      width: 31.8rem;
      bottom: -10.4rem;
      right: 8.6rem;
      margin-right: 0;
    }
  }
}

/* place
------------------------------- */

.place {
  position: relative;
  padding: 226px 0 126px;
  background-color: #fff;

  &__img01 {
    height: 604px;
    object-position: 50% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__in {
    width: 1100px;
    max-width: 85%;
    position: relative;
    z-index: 1;
  }

  &__ttl {
    font-size: 25px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    margin-bottom: 18px;
  }

  &__p {
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.05em;
    color: #fff;
    text-align: center;
    margin-bottom: 128px;
  }
  
  @media only screen and (max-width: 767px) {
    padding: 7rem 0 8.6rem;

    &__img01 {
      height: 38.5rem;
    }

    &__in {
      width: 29.5rem;
      max-width: 100%;
    }

    &__ttl {
      font-size: 1.8rem;
      line-height: 3.9rem;
      margin-bottom: 1.4rem;
    }

    &__p {
      font-size: 1.3rem;
      line-height: 2.6rem;
      text-align: justify;
      text-justify: inter-ideograph;
      margin-bottom: 6.7rem;
    }
  }
}

/* flow
------------------------------- */

.flow {
  padding-top: 114px;
  background-color: var.$colorLgl;

  &__ttl-large {
    font-family: "Quattrocento", serif;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: #fff;
    text-align: left;
    margin-bottom: 45px;

    ._sub {
      display: block;
      font-family: var.$fontMincho;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.05em;
      text-align: left;
      margin-top: 2px;
    }
  }

  &__ttl-mid {
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #fff;
    text-align: left;
    margin-bottom: 15px;
  }

  &__p {
    font-size: 13px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #fff;
    font-feature-settings: "palt";
    text-align: justify;
    text-justify: inter-ideograph;
    word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 28px;
  }

  &__in {
    display: -webkit-flex;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(((100vw - 1100px) / 2) + 1100px);
    max-width: 94%;
    margin-left: auto;
    margin-right: 0;
    background-color: var.$colorBe;

    &__cell {
      &._blockA {
        width: 550px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &._blockB {
        width: calc(100% - 550px);
        padding-top: 160px;
        background-image: url("../../img/top/bg_01.png");
        background-size: auto 100%;
        background-position: 0 center;
        background-repeat: no-repeat;
        .webp & {
          background-image: url("../../img/top/bg_01.png.webp");
        }

        ._inner {
          width: 550px;
          padding-right: 50px;
          padding-left: 125px;
          margin-left: 0;
          margin-right: auto;
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        height: 654px;

        &._blockA {
          width: calc(100% - 550px);
          img {
            height: 100%;
            object-fit: cover;
          }
        }

        &._blockB {
          width: 550px;
        }
      }
    }
  }
  
  @media only screen and (max-width: 767px) {
    padding-top: 0;
    background-color: #fff;
    
    &__ttl-large {
      font-size: 2.8rem;
      margin-right: -1em;
      margin-bottom: 3rem;

      ._sub {
        font-size: 1.3rem;
        margin-top: 0.2rem;
      }
    }

    &__ttl-mid {
      font-size: 1.6rem;
      line-height: 2.9rem;
      margin-right: -2em;
      margin-bottom: 1.7rem;
    }

    &__p {
      font-size: 1.3rem;
      line-height: 2.9rem;
      margin-bottom: 3rem;
    }

    &__in {
      display: block;
      width: 35.5rem;
      max-width: 100%;

      &__cell {
        &._blockA {
          width: 100%;
        }

        &._blockB {
          width: 100%;
          padding-top: 5.4rem;
          padding-bottom: 8rem;
          background-image: url("../../img/top/bg_01_sp.png");
          background-size: 100% auto;
          .webp & {
            background-image: url("../../img/top/bg_01_sp.png.webp");
          }

          ._inner {
            width: 25.5rem;
            padding-right: 0;
            padding-left: 0;
            margin-left: 4rem;
          }
        }
      }
    }
  }
}

/* report
------------------------------- */

.report {
  padding: 77px 0 127px;

  .btn-more {
    margin: 20px auto 0;
  }

  &__in {
    width: 1100px;
    max-width: 85%;
  }
  
  @media only screen and (max-width: 767px) {
    padding: 6.5rem 0 10.1rem;

    .btn-more {
      width: 29.5rem;
      margin: 4.9rem auto 0;
    }

    &__in {
      width: 100%;
      max-width: none;
    }
  }
}

/* gallery
------------------------------- */

.gallery {
  padding: 38px 0 120px;
  background-color: var.$colorLgl;
  position: relative;

  .ttl-large-en {
    width: 100%;
    transform: translateY(-65px);
    margin-bottom: 0;
  }

  .btn-more {
    margin: 57px auto 0;
  }
  
  @media only screen and (max-width: 767px) {
    padding: 3.7rem 0 12rem;

    .btn-more {
      margin: 4.1rem auto 0;
    }
  }
}

/*PHOTO GALLERY*/

.photo-gallery {
  @include var.fxb_s_s;
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 768px) {
    align-self: 96%;
  }

  &__item {
    display: block;
    width: 20%;
  }

  &__img {
    display: block;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  
  @media only screen and (max-width: 767px) {
    @include var.fxb_s_s;

    &__item {
      width: 50%;
    }
  }
}

/* news-list
------------------------------- */

.news-list {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  padding: 78px 0 116px;

  &__in {
    width: calc(1000px + ((100% - 1000px) / 2));
    max-width: 94%;
    margin-left: auto;
    margin-right: 0;

    .ttl-large-en {
      font-size: 20px;
      text-align: left;
      margin-bottom: 25px;

      ._sub {
        font-size: 13px;
        margin-top: 5px;
      }
    }

    .btn-more {
      margin-top: 42px;
      margin-left: 0;
      margin-right: auto;
    }
  }

  &__item {
    @include var.fxb_s_s;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 29px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e6e6e6;

    &__date {
      display: block;
      width: 138px;
      font-family: "Quattrocento", serif;
      line-height: 1.6;
      letter-spacing: 0.1em;
      color: var.$colorDbe;
    }

    &__ttl {
      width: calc(100% - 138px);
      letter-spacing: 0.05em;
      padding-right: 2em;
      color: var.$baseColor;
    }
  }
  
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 5.5rem 0 10rem;

    &__in {
      width: 33.4rem;
      max-width: 100%;;

      .ttl-large-en {
        font-size: 2rem;
        margin-bottom: 5.2rem;

        ._sub {
          font-size: 1.3rem;
          margin-top: 0.5rem;
        }
      }

      .btn-more {
        margin-top: 4rem;
      }
    }

    &__item {
      display: block;
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-bottom: 2.8rem;
      padding-bottom: 2.5rem;

      &__date {
        width: 100%;
        margin-bottom: 1rem;
      }

      &__ttl {
        width: 100%;
      }
    }  
  }
}

/* Contact info
------------------------------- */

.contact-info {
  padding: 85px 0 53px;
  background-color: var.$colorLgl;
  text-align: center;

  .ttl-large-en {
    font-size: 20px;
    margin-bottom: 24px;

    ._sub {
      font-size: 13px;
      margin-top: 11px;
    }
  }

  .btn-more {
    margin: 0 auto 22px;
    @media only screen and (min-width: 768px) {
      width: 275px;
    }
  }

  &__tel {
    font-family: "Quattrocento", serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.1em;
    color: var.$colorGr;
    text-align: center;
  }

  &__p {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.05em;
    color: var.$colorGr;
    text-align: center;
    margin-top: 7px;
  }
  
  @media only screen and (max-width: 767px) {
    padding: 7.7rem 0 4.9rem;

    .ttl-large-en {
      font-size: 2rem;
      margin-bottom: 3rem;

      ._sub {
        font-size: 1.3rem;
        margin-top: 0.9rem;
      }
    }

    .btn-more {
      margin: 0 auto 1.9rem;
    }

    &__tel {
      font-size: 2rem;
    }

    &__p {
      font-size: 1.3rem;
      margin-top: 0.1rem;
    }
  }
}

/* btm-nav
------------------------------- */

.btm-nav {
  padding: 75px 0 90px;
  background-image: url("../../img/top/bg_02.jpg.webp");
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;

  &__in {
    @media only screen and (min-width: 768px) {
      max-width: 94%;
    }
  }

  .no-webp & {
    background-image: url("../../img/top/bg_02.jpg");
  }

  .drawer-contact__ttl-mid {
    color: #fff;
  }
  
  @media only screen and (max-width: 767px) {
    padding: 3.4rem 0 6.9rem;
    background-image: url("../../img/top/bg_02_sp.jpg.webp");
    background-size: 100% auto;
    background-color: #060909;

    .no-webp & {
      background-image: url("../../img/top/bg_02_sp.jpg");
    }

    ._aco-block-sp {
      padding-top: 1.7rem;
    }
  }
}


.bridal-fair {
  padding: 0 0 100px;
  border-top: none;
  border-bottom: 1px solid #e6e6e6;

  .ttl-large-en {
    transform: translateY(-26px);
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 0 4rem;
    border-bottom: 1px solid #e6e6e6;

    .ttl-large-en {
      transform: translateY(-2.5rem);
      margin-bottom: 5px;
    }
  }
}
